<template>
  <div class="box-body">
    <card-items :data_search="data_search"
                :filters_query="filter_task"
                :relations="relations"
                :relations_count="relations_count"
                component="task-item"
                item_classes="col-md-6 col-sm-12 col-xs-12 mb-4"
                api_resource="/tasks">
    </card-items>
  </div>
</template>

<script>
import Vue from "vue";
import CardItems from './../../../components/pagination/card-items';

Vue.component('task-item', (resolve) => {
  return require(['./../../tasks/item'], resolve);
});

export default {
  name: 'car-tasks',

  components: {
    CardItems,
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
    data_search: {
      type: String,
      required: false
    }
  },

  data: () => ({
    relations: [
      'car.branch',
      'client',
      'type',
      'responsible',
      'last_comment.creator',
      'creator',
      'editor',
    ],
    relations_count: [
      'comments',
      'files',
    ],

    filter_task: {
      car_id: null,
    },
  }),

  async created() {
    try {
      this.filter_task.car_id = this.carId;
      this.updateFilters();
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {},

  methods: {
    updateFilters() {
      this.EventBus.$emit('card-filter-changed');
    },
  },
}
</script>

<style scoped>

</style>
